import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PUT, GET } from "../../../config/api";
import {
  notificationTypes,
  openNotification,
} from "../../../utils/notifications";

const AddDefects = ({
  number1,
  title1,
  priority1,
  description1,
  status1,
  createdDate1,
  dueDate1,
  reporter1,
  assignee1,
  defectsId,
  jsonBData,
  projectId1,
  issueTypeId1,
  externalDefectId,
  externalDefectKey,
  imageNames,
}) => {
  console.log(issueTypeId1, "PPPPPPPPPPPPPPP issueTypeId1");
  console.log(defectsId, "PPPPPPPPPPPPPPP defectsId");
  const navigate = useNavigate();
  // const [defect, setDefect] = useState(defect1);
  const [title, setTitle] = useState(title1);
  const [number, setNumber] = useState(number1);
  const sessionID = useSelector((state) => state.user.sessionId);
  const [externalId, setExternalId] = useState(externalDefectKey);
  const [priority, setPriority] = useState(priority1);
  const [description, setDescription] = useState(description1);
  const [status, setStatus] = useState(status1);
  const [createdDate, setCreatedDate] = useState(createdDate1);
  const [dueDate, setDueDate] = useState(dueDate1);
  const [reporter, setReporter] = useState(reporter1);
  const [assignee, setAssignee] = useState(assignee1);
  const [users, setUsers] = useState([]);
  console.log(assignee1, "assignee1 assignee1assignee1")
  const [reporterValue, setReporterValue] = useState("");
  const [assigneeValue, setAssigneeValue] = useState("");

  const [reporterValueGreentick, setReporterValueGreentick] = useState("");
  const [assigneeValueGreentick, setAssigneeValueGreentick] = useState("");

  const sessionId = useSelector((state) => state.user.sessionId);
  const formSettingData = useSelector((state) => state.user.settings);

  const [formData, setFormData] = useState([]);
  const [accountType, setAccountType] = useState("greentick");
  const [companyId, setCompanyId] = useState("");

  const [projectId, setProjectId] = useState(projectId1);
  const [projectName, setProjectName] = useState("");
  const [projectIdData, setProjectIdData] = useState([]);

  const [issueTypeId, setIssueTypeId] = useState(issueTypeId1);
  const [issueTypeName, setIssueTypeName] = useState("");
  const [issueTypeIdData, setIssueTypeIdData] = useState([]);
  const [reporterData, setReporterData] = useState([]);
  const [issueStatusBoardData, setIssueStatusBoardData] = useState([]);
  const [statusValueData, setStatusValueData] = useState([]);
  const [jiraStatusBoardId, setJiraStatusBoardId] = useState("");

  const [jsonB, setJSONB] = useState(jsonBData);
  const [fileData, setFileData] = useState("");

  useEffect(() => {
    getAllUsers();
    if (formSettingData && Object.keys(formSettingData) && Object.keys(formSettingData).length > 0) {
      const form =
        formSettingData &&
        formSettingData.defectsConfiguration &&
        formSettingData.defectsConfiguration;
      setFormData(form.length > 0 ? form : []);
      console.log(form, "form 1234567654321")
      if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
        setAccountType(formSettingData.accountType);
        setCompanyId(formSettingData.companyId);
        getProjectIds(
          formSettingData.userName,
          formSettingData.url,
          formSettingData.token
        );
        getallIssueStatus(projectId1, issueTypeId1);
        getallIssueStatusBoardValues(externalDefectKey)
      }
    }
  }, []);

  useEffect(() => {
    const getUsers = () => {
      GET("/getUsers", { sessionID })
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 304) {
            const response = res.data.message;
            setUsers(response);
            console.log(response, "res.data.message");
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUsers();
  }, [sessionID]);

  useEffect(() => {
    if (users) {
      for (var i = 0; i < users.length; i++) {
        if (reporter === users[i].email) {
          setReporterValueGreentick(users[i].username);
        } else {
          setReporterValueGreentick(reporter1)
        }
        if (assignee === users[i].email) {
          setAssigneeValueGreentick(users[i].username);
        } else {
          setAssigneeValueGreentick(assignee1)
        }
      }
    }
  }, [reporter, assignee, reporterData]);

  const getProjectIds = async (userName, url, token) => {
    GET(`/getallproject?userName=${userName}&url=${url}&token=${token}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setProjectIdData(res.data.message);
          if (res.data.message.length > 0) {
            for (let index = 0; index < res.data.message.length; index++) {
              if (res.data.message[index].id === projectId1) {
                setProjectName(res.data.message[index].name);
                getIssueTypeIds(res.data.message[index].id);
                break
              } else {
                setProjectName(projectId1);
                setIssueTypeName(issueTypeId1)
              }
            }
          }
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getIssueTypeIds = async (id) => {
    GET(
      `/getissuetypeid?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&id=${id}`,
      {
        sessionID: sessionId,
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          setIssueTypeIdData(res.data.message);
          if (res.data.message.length > 0) {
            for (let index = 0; index < res.data.message.length; index++) {
              if (res.data.message[index].id === issueTypeId1) {
                setIssueTypeName(res.data.message[index].name);
              }
            }
          }
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const selectOptions = ["Open","Reopen", "In-Progress","Fixed","Duplicate","Rejected","Deferred", "Closed"];

  const selectOptions1 = ["Highest", "High", "Medium", "Low", "Lowest"];

  useEffect(() => {
    for (var i = 0; i < reporterData.length; i++) {
      if (reporter === reporterData[i].accountId) {
        console.log(reporterData[i].displayName, "reporterData[i].displayName")
        setReporterValue(reporterData[i].displayName);
        break
      } else {
        console.log(reporter1, "line 205")
        setReporterValue(reporter1)
      }
    }
    for (var i = 0; i < reporterData.length; i++) {
      if (assignee === reporterData[i].accountId) {
        setAssigneeValue(reporterData[i].displayName);
        break
      } else {
        setAssigneeValue(assignee1)
      }
    }
  }, [reporter, assignee, reporterData]);

  const getAllUsers = async () => {
    GET(`/getallusers`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setReporterData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting id, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const getallIssueStatus = async (projectId, issueTypeId1) => {
    GET(`/getallissuestatus?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&projectId=${projectId}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          let filteredData = res.data.message.filter(item => item.id === issueTypeId1);
          setStatusValueData(filteredData);
          console.log(filteredData, "filteredData filteredData")
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting ststus, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };

  const handleStatusId = (e) => {
    const filteredStatusId = issueStatusBoardData.filter((item) => item.name === e);
    if (filteredStatusId.length > 0) {
       setJiraStatusBoardId(filteredStatusId[0].id);
      console.log(filteredStatusId[0], "entered value");
    } else {
      console.log("No matching item found for", e);
    }
  };
  

  const getallIssueStatusBoardValues = async (externalDefectKey) => {
    GET(`/getstatusboardvaluesbyid?userName=${formSettingData.userName}&url=${formSettingData.url}&token=${formSettingData.token}&externalDefectKey=${externalDefectKey}`, {
      sessionID: sessionId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIssueStatusBoardData(res.data.message);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting ststus board values, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting id, Please try again"
        );
      });
  };
  const updateDefects = () => {
    const data = {
      // defect: defect,
      title: title,
      createdDate: createdDate,
      priority: priority,
      description: description,
      dueDate: dueDate,
      assignee: assignee,
      reporter: reporter,
      status: status,
      projectId,
      issueTypeId,
      other: jsonB,
    };

    let formsData = new FormData();

    formsData.append("image", fileData);
    formsData.append("title", title);
    formsData.append("description", description);
    formsData.append("priority", priority);
    formsData.append("createdDate", createdDate);
    formsData.append("status", status);
    formsData.append("projectId", projectId);
    formsData.append("issueTypeId", issueTypeId);
    formsData.append("other", JSON.stringify(jsonB));
    formsData.append("companyId", companyId);
    formsData.append("externalDefectId", externalDefectId);
    formsData.append("externalDefectKey", externalDefectKey);
    formsData.append("dueDate", dueDate);
    formsData.append("reporter", reporter);
    formsData.append("assignee", assignee); 
    formsData.append("jiraStatusBoardId", jiraStatusBoardId);
    console.log("dueDate", dueDate)

    if (new Date(createdDate) >= new Date(dueDate)) {
      openNotification(
        notificationTypes.ERROR,
        "Error: 80210",
        "Enter valid due date"
      );
      return;
    }

    if (title.length > 0 && dueDate.length > 0 && status.length > 0 && priority.length > 0 &&
      description.length > 0 && assignee.length > 0 && reporter.length > 0) {
        PUT(`/defectsEdit/${defectsId}`, formsData, {
          sessionID: sessionId,
        })
          .then((res) => {
            if (res.status === 200) {
              openNotification(
                notificationTypes.SUCCESS,
                "Success",
                "Defects data updated successfully"
              );
              navigate("/defects");
            } else {
              openNotification(
                notificationTypes.ERROR,
                "Error: 82010",
                "Error in updating Defects. Please try again"
              );
            }
          })
          .catch(() => {
            openNotification(
              notificationTypes.ERROR,
              "Error: 82010",
              "Error in updating Defects. Please try again"
            );
          });
      }else{
         if (title === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please enter Title'
          )
        }  else if (dueDate === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Due date'
          )
        } else if (status === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Status'
          )
        } else if (priority === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Priority'
          )
        }  else if (reporter === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Reporter'
          )
        }else if (assignee === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please select a Assignee'
          )
        } else if (description === '') {
          openNotification(
            notificationTypes.ERROR,
            'Error: 30830',
            'Please enter description'
          )
        }
      }

  };

  const handleFormJSONData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let type = e.target.type;

    for (let i = 0; i < value.length - 3; i++) {
      if (value[i] === value[i + 1] && value[i] === value[i + 2] && value[i] === value[i + 3]) {
        alert("Four or more consecutive identical characters are not allowed!");
        e.target.value = '';
        return;
      }
    }
    let found = false;
    if (jsonB.length > 0) {
      const newState = jsonB.map((obj) => {
        if (obj.name === name) {
          found = true;
          return { ...obj, value: type === "number" ? parseFloat(value) : value };
        }
        return obj;
      });
      setJSONB(newState);
      console.log(newState, "newState 299")
    }

    let arr = [...jsonB];
    if (!found) {
      arr.push({
        name: name,
        value: value,
      });

      setJSONB(arr);
      console.log(arr, "arr 308")
    }
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // const specialCharacterCount = (inputValue.match(/[^\w\s]/g) || []).length;
    // if (specialCharacterCount > 5) {
    //     alert("More than 5 special characters are not allowed!");
    //     e.target.value = '';
    //     return;
    // }
    for (let i = 0; i < inputValue.length - 3; i++) {
      if (inputValue[i] === inputValue[i + 1] && inputValue[i] === inputValue[i + 2] && inputValue[i] === inputValue[i + 3]) {
        alert("Four or more consecutive identical characters are not allowed!");
        e.target.value = '';
        return;
      }
    }
  };

  return (
    <>
      <div className="flex justify-end">
        <button
          onClick={() => navigate("/defects")}
          class="mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 px-6 rounded"
        >
          Back
        </button>
        <button
          className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
          type="button"
          onClick={updateDefects}
        >
          Save
        </button>
      </div>

      <div className="mt-5 bg-gray-200 p-4 rounded-xl">
        <div className="bg-white p-3 rounded-xl">
          <div className="grid grid-col-10 gap-x-4 gap-y-2">

            {" "}
            <div className="col-span-5">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Project &nbsp;
              </div>
              <h1 className="text-black">
                {accountType === "jira" ? (
                  <input
                    readOnly
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={projectName}
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                ) : (
                  <input
                    readOnly
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={projectId}
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                )}

              </h1>
            </div>
            <div className="col-span-5">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Issue Type Id &nbsp;
              </div>
              <h1 className="text-black">
                {accountType === "jira" ? (
                  <input
                    readOnly
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={issueTypeName}
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                ) : (
                  <input
                    readOnly
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={issueTypeId}
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                )}
              </h1>
            </div>
            {/* <div className="col-span-10 ">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Defect
              </div>
              <h1 className="text-black">
                <input
                  type="text"
                  className="p-2 w-full border-2 rounded-lg"
                  value={defect}
                  onChange={(e) => setDefect(e.target.value)}
                />
              </h1>
            </div> */}
            <div className={accountType === "jira" ? "col-span-5" : "col-span-10"}>
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Number &nbsp;
              </div>
              <h1 className="text-black">
                <input
                  readOnly
                  type="text"
                  className="p-2 w-full border-2 rounded-lg"
                  value={number}
                // onChange={(e) => setTitle(e.target.value)}
                />
              </h1>
            </div>
            {accountType === "jira" ? (
              <div className="col-span-5">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  External Defect Key &nbsp;
                </div>
                <h1 className="text-black">
                  <input
                    readOnly
                    type="text"
                    className="p-2 w-full border-2 rounded-lg"
                    value={externalId}
                  // onChange={(e) => setTitle(e.target.value)}
                  />
                </h1>
              </div>
            ) : ("")}
            <div className="col-span-10">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Title &nbsp;
              </div>
              <h1 className="text-black">
                <input
                  type="text"
                  className="p-2 w-full border-2 rounded-lg"
                  value={title}
                  onChange={(e) => { handleInputChange(e); setTitle(e.target.value) }}
                />
              </h1>
            </div>
            <div className="col-span-3">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Created Date &nbsp;
              </div>
              <h1 className="text-black">
                {createdDate ? (
                  <input
                    type="text"
                    readOnly
                    className="p-2 w-full border-2 rounded-lg"
                    // defaultValue={moment(createdDate)
                    //   .utc()
                    //   .format("DD-MM-YYYY")}
                    defaultValue={(createdDate)}
                  // onClick={() => {
                  //   setCreatedDate(false);
                  // }}
                  />
                ) : (
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    // value={moment(rDate).utc().format("DD-MM-YYYY")}
                    onChange={(e) => setCreatedDate(e.target.value)}
                  />
                )}
              </h1>
            </div>
            <div className="col-span-3">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Due Date &nbsp;
              </div>
              <h1 className="text-black">
                {dueDate ? (
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    defaultValue={(dueDate)}
                    // defaultValue={moment(dueDate)
                    //   .utc()
                    //   .format("DD-MM-YYYY")}
                    onClick={() => {
                      setDueDate(false);
                    }}
                  />
                ) : (
                  <input
                    type="date"
                    className="p-2 w-full border-2 rounded-lg"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                )}
              </h1>
            </div>
            {accountType === "jira" ? (
              <div className="col-span-4">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Status &nbsp;
                </div>
                <h1 className="text-black">
                  <select
                    className="p-2 w-full border-2 rounded-lg"
                    name="overallStatus"
                    id="overallStatus"
                    onChange={(e) => {setStatus(e.target.value); handleStatusId(e.target.value)}}
                  >
                    {statusValueData.map((obj) => {
                      let selected = "";
                      if (obj.status.trim() === status.trim()) {
                        selected = "selected";
                      }
                      return <option key={obj.id} value={obj.status} selected={selected}>{obj.status}</option>;
                    })}

                  </select>
                </h1>
              </div>) : (
              <div className="col-span-4">
                <div className="text-sm mt-2" style={{ color: "grey" }}>
                  Status &nbsp;
                </div>
                <h1 className="text-black">
                  <select
                    className="p-2 w-full border-2 rounded-lg"
                    name="overallStatus"
                    id="overallStatus"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {selectOptions.map((obj) => {
                      let selected = "";
                      if (obj.trim().localeCompare(status.trim()) === 0) {
                        selected = "selected";
                      } else {
                        selected = "";
                      }
                      return <option selected={selected}>{obj.trim()}</option>;
                    })}
                  </select>
                </h1>
              </div>
            )}
            <div className="col-span-3">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Priority &nbsp;
              </div>
              <h1 className="text-black">
                <select
                  className="p-2 w-full border-2 rounded-lg"
                  name="overallStatus"
                  id="overallStatus"
                  onChange={(e) => setPriority(e.target.value)}
                >
                  {selectOptions1.map((obj) => {
                    let selected = "";
                    if (obj.trim().localeCompare(priority.trim()) === 0) {
                      selected = "selected";
                    } else {
                      selected = "";
                    }
                    return <option selected={selected}>{obj.trim()}</option>;
                  })}
                </select>
              </h1>
            </div>
            {accountType === "jira" ? (
              <>
                <div className="col-span-3">
                  <div className="text-sm mt-2" style={{ color: "grey" }}>
                    Reporter &nbsp;
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="reporter"
                      id="reporter"
                      onChange={(e) => {
                        setReporter(e.target.value);
                      }}
                    >
                      <option>{reporter ? reporterValue : "Select reporter"}</option>
                      {reporterData.map((items, index) => (
                        <option key={index} value={items.accountId}>
                          {reporterValue === items.displayName ? reporterValue : items.displayName}
                        </option>
                      ))}
                    </select>
                  </h1>
                </div>
                <div className="col-span-4">
                  <div className="text-sm mt-2" style={{ color: "grey" }}>
                    Assignee &nbsp;
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="assignee"
                      id="assignee"
                      onChange={(e) => {
                        setAssignee(e.target.value);
                      }}
                    >
                      <option>{assignee ? assigneeValue : "Select assignee"}</option>
                      {reporterData.map((items, index) => (
                        <option key={index} value={items.accountId}>
                          {assigneeValue === items.displayName ? assigneeValue : items.displayName}
                        </option>
                      ))}
                    </select>
                  </h1>
                </div>
              </>
            ) : (
              <>
                <div className="col-span-3">
                  <div className="text-sm mt-2" style={{ color: "grey" }}>
                    Reporter &nbsp;
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="reporter"
                      id="reporter"
                      onChange={(e) => {
                        setReporter(e.target.value);
                      }}
                    >
                      <option>
                        {reporter ? reporterValueGreentick : "Select reporter"}
                      </option>
                      {users.map((items, index) => {
                        return (
                          <>
                            <option value={items.email}>{items.username}</option>
                          </>
                        );
                      })}
                    </select>
                  </h1>
                </div>
                <div className="col-span-4">
                  <div className="text-sm mt-2" style={{ color: "grey" }}>
                    Assignee &nbsp;
                  </div>
                  <h1 className="text-black">
                    <select
                      className="p-2 w-full border-2 rounded-lg"
                      name="assignee"
                      id="assignee"
                      onChange={(e) => {
                        setAssignee(e.target.value);
                      }}
                    >
                       <option>
                        {assignee ? assigneeValueGreentick : "Select reporter"}
                      </option>
                      {users.map((items, index) => {
                        return (
                          <>
                            <option value={items.email}>{items.username}</option>
                          </>
                        );
                      })}
                    </select>
                  </h1>
                </div></>
            )}

            <div className="col-span-10">
              <div className="text-sm mt-2" style={{ color: "grey" }}>
                Description &nbsp;
              </div>
              <h1 className="text-black">
                <textarea
                  value={description}
                  onChange={(e) => { handleInputChange(e); setDescription(e.target.value) }}
                  placeholder="Enter Description"
                  className="p-2 w-full border-2 rounded-lg"
                />
              </h1>
            </div>
            {/* <div className="col-span-10">
              <label
                style={{ color: "grey" }}
                className="text-sm mt-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="multiple_files"
              >
                Attachements
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="multiple_files"
                type="file"
                multiple
                ref={inputRef}
                onChange={handleFileChange}
              />
              {console.log(imageName, "llllllllllllllllll")}
              {fileData
                ? ""
                : imageNames &&
                imageNames.map((item, index) => {
                  console.log(item, "llllllllllllllllll");
                  return "selected File Name is : " + item.name + ",";
                })}
              {/* {fileData ? "" : "selected File Name is : " + imageName} 
            </div> */}

            {formData &&
              formData.map((item, index) => {
                return (
                  <div className="col-span-10" key={index}>
                    {(() => {
                      if (item.type === "text") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <h1 className="text-black">
                              <input
                                type={item.subtype}
                                name={item.name}
                                className="p-2 w-full border-2 rounded-lg"
                                placeholder={item.placeholder}
                                defaultValue={values}
                                required
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                  handleInputChange(e);
                                }}
                              />
                            </h1>
                          </>
                        );
                      } else if (item.type === "textarea") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <textarea
                              name={item.name}
                              defaultValue={values}
                              type={item.subtype}
                              onChange={(e) => {
                                handleFormJSONData(e);
                                handleInputChange(e);
                              }}
                              placeholder={item.placeholder}
                              className="p-2 w-full border-2 rounded-lg"
                            />
                          </>
                        );
                      } else if (item.type === "select") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <select
                              className="p-2 w-full border-2 rounded-lg"
                              // name="overallStatus"
                              id={item.name + index}
                              name={item.name}
                              onChange={(e) => {
                                handleFormJSONData(e);
                              }}
                            >
                              <option defaultValue={values}>{values}</option>
                              {item.values.map((items, index1) => {
                                return (
                                  <>
                                    <option value={items.value}>
                                      {items.label}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </>
                        );
                      } else if (item.type === "radio-group") {
                        return (
                          <>
                            {item.values.map((items, index1) => {
                              let values = "";
                              jsonBData &&
                                jsonBData.map((val, ind) => {
                                  if (val.name === item.name) {
                                    values = val.value;
                                  }
                                });
                              return (
                                <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
                                  <input
                                    type="radio"
                                    name={item.name}
                                    defaultValue={values}
                                    onChange={(e) => {
                                      handleFormJSONData(e);
                                    }}
                                  />
                                  &nbsp;{items.label}
                                </label>
                              );
                            })}
                          </>
                        );
                      } else if (item.type === "checkbox-group") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            {item.values.map((items, index1) => {
                              return (
                                <label className="text-base text-gray-600 font-medium px-4 py-4 whitespace-nowrap">
                                  <input
                                    type="checkbox"
                                    name={item.name}
                                    defaultValue={values}
                                    onChange={(e) => {
                                      handleFormJSONData(e);
                                    }}
                                  />
                                  &nbsp;{items.label}
                                </label>
                              );
                            })}
                          </>
                        );
                      } else if (item.type === "number") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <h1 className="text-black">
                              <input
                                type={item.subtype}
                                className="p-2 w-full border-2 rounded-lg"
                                placeholder={item.placeholder}
                                defaultValue={values}
                                required
                                name={item.name}
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                }}
                              />
                            </h1>
                          </>
                        );
                      } else if (item.type === "date") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <h1 className="text-black">
                              <input
                                type={item.subtype}
                                className="p-2 w-full border-2 rounded-lg"
                                placeholder={item.placeholder}
                                required
                                name={item.name}
                                defaultValue={values}
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                }}
                              />
                            </h1>
                          </>
                        );
                      } else if (item.type === "file") {
                        let values = "";
                        jsonBData &&
                          jsonBData.map((val, ind) => {
                            if (val.name === item.name) {
                              values = val.value;
                            }
                          });
                        return (
                          <>
                            <div
                              className="text-sm mt-2"
                              style={{ color: "grey" }}
                            >
                              {item.label}
                            </div>
                            <h1 className="text-black">
                              <input
                                type={item.type}
                                className="p-2 w-full border-2 rounded-lg"
                                placeholder={item.placeholder}
                                required
                                name={item.name}
                                defaultValue={values}
                                onChange={(e) => {
                                  handleFormJSONData(e);
                                }}
                              />
                            </h1>
                          </>
                        );
                      }
                    })()}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-5 gap-4 mt-5">
        <div className="col-end-7">
          <button
            className="w-full bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black text-green-600 font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end hidden"
            type="button"
            onClick={onSave(rName, rVersion, rDate, rDesc, rOverallStatus)}
          >
            Update
          </button>
        </div>
      </div> */}
    </>
  );
};

export default AddDefects;
