import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GET, POST } from "../../../config/api";
import {
  notificationTypes,
  openNotification,
} from "../../../utils/notifications";

const Comments = ({ projectId1, issueTypeId1, externalDefectId,  defectsId,}) => {
   const navigate = useNavigate();
  const sessionId = useSelector((state) => state.user.sessionId);
  const formSettingData = useSelector((state) => state.user.settings);
  const formSettingDataUserId = useSelector((state) => state.user.userId);
  console.log(formSettingData, "formSettingData 11111111");

  const userId = useSelector((state) => state.user.userId);
  console.log(userId, "userId 11111111");

  const user_name = useSelector((state) => state.user.username);
  console.log(user_name, "username 11111111");

  const [accountType, setAccountType] = useState("greentick");
  const [comments, setComments] = useState([]);

  const [userName, setUserName] = useState("");
  const [url, setURL] = useState("");
  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  const [commentsValue, setCommentsValue] = useState("");

  useEffect(() => {
    if (formSettingData && Object.keys(formSettingData) && Object.keys(formSettingData).length > 0) {
      if (formSettingData && formSettingData.accountType && formSettingData.accountType === "jira") {
        setAccountType(formSettingData.accountType);
        setUserName(formSettingData.userName);
        setURL(formSettingData.url);
        setToken(formSettingData.token);
        setId(externalDefectId);

        getComments(
          formSettingData.userName,
          formSettingData.url,
          formSettingDataUserId,
          formSettingData.token,
          formSettingData.accountType
        );
      } else if(formSettingData) {
        getComments(
          formSettingData.userName,
          formSettingData.url,
          formSettingDataUserId,
          formSettingData.token,
          formSettingData.accountType
        );
      }
    } else if(formSettingData){
      getComments(
        formSettingData.userName,
        formSettingData.url,
        formSettingDataUserId,
        formSettingData.token,
        formSettingData.accountType
      );
    }
  }, []);

  const getComments = async (userName, url,userId, token, accountType) => {
    GET(
      `/comments?userName=${userName}&url=${url}&userId=${userId}&token=${token}&id=${externalDefectId}&accountType=${accountType}&defectId=${defectsId}`,
      {
        sessionID: sessionId,
      }
    )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.message.comments,"res.data.message.comments 74")
          setComments(res.data.message.comments);
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error in getting comments, Please try again"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error in getting comments, Please try again"
        );
      });
  };

  const handleComment = async () => {
    console.log(commentsValue, "value 123");
    const data = {
      comments: commentsValue,
      accountType: accountType,
      userName: userName,
      url: url,
      token: token,
      id: id,
      name: user_name,
      defectId: defectsId,
      userId:userId
    };
    if (commentsValue === "") {
      openNotification(
        notificationTypes.ERROR,
        "Error: 80210",
        "Please enter the comments"
      );

      return;
    }
    let input1 = document.getElementById('input1');
    POST("/addComments", data, { sessionID: sessionId })
      .then((res) => {
        console.log(res, "addComments Hiiiiiiiiii");
        if (res.data.status === 200) {
          openNotification(
            notificationTypes.SUCCESS,
            "Comment added Successfully"
          );
          input1.value = "";
          getComments(userName, url, userId, token, accountType);
          setCommentsValue("")
        } else {
          openNotification(
            notificationTypes.ERROR,
            "Error while creating Defects, Please try again later"
          );
        }
      })
      .catch(() => {
        openNotification(
          notificationTypes.ERROR,
          "Error while creating comments, Please try again later"
        );
      });
  };

  return (
    <> <div>
    <div className="col-span-10 me-3 mb-2 flex justify-end">
    <button
      onClick={() => navigate("/defects")}
      class="mr-2 bg-blue-500 hover:bg-blue-700 text-lg text-white font-bold p-2 px-6 rounded"
    > Back
    </button>
    <button
      className="bg-customGreenFour text-black text-lg hover:bg-green-300 border-2 hover:border-black font-medium py-1 px-4 rounded-xl focus:outline-none focus:shadow-outline flex flex-end"
      type="button"
      onClick={handleComment}
    >
      Save
    </button>
  </div>
      <div className="mt-5 bg-gray-200 p-4 rounded-xl">
        <div className="bg-white p-3 rounded-xl">
          <div className="col-span-10">
            <div className="text-lg mt-2">
              <b>Comments :</b>
            </div>
            {comments.map((items, index) => {
              return (
                <>
                  <div key={index}>
                    <b>
                      {index + 1 + ") "}
                      {items.name}
                    </b>
                    <div className="text-sm mt-2">{items.comment}</div>
                  </div>
                  <br />
                </>
              );
            })}
            <br />

            <div className="col-span-8">
              <div className="text-sm mt-2" style={{ color: "black" }}>
                Add Comment &nbsp;
              </div>
              <h1 className="text-black">
                <textarea
                  id = "input1" 
                  onChange={(e) => setCommentsValue(e.target.value)}
                  placeholder="Enter New Comment"
                  className="p-2 w-full border-2 rounded-lg"
                />
              </h1>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Comments;
